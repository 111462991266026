import { createAppSlice } from "@redux/createAppSlice"
import { type PayloadAction } from "@reduxjs/toolkit"

export interface DeliverCampaignSliceState {
  emailSmsDetails: {
    emailData: EmailDetails
    smsData: SmsDetails
    emailSmsLanguage: string

    testEmailSmsDetails?: testEmailSmsDetails | null
  }
  discountDetails: DiscountDetails | null
  receiverDetails: {
    spsPayDetails: spsPayDetails | null
    pageCount: number
    totalCount: number
    isReadyToProcess: boolean
  }
}
export interface spsPayDetails {
  available_balance: string
  budget: string
  is_order_processed: boolean
  is_ready_to_process: boolean
  order_id: number
  payable_amount: string
  total_count: number | null
}

export interface DiscountDetails {
  discount_percentage: number
  discount_percentage_to_apply: number
}

export interface testEmailSmsDetails {
  sample_gift_code: string
  expiration_date: string
  corporate_name: string
  app_download_link?: string
  web_redemption_link?: string
  show_sms_content?: boolean
}
export interface EmailDetails {
  subject: string
  isEmailEnabled: boolean
  deliveryDate: string
  bannerImage: any
  body: string
}

export interface SmsDetails {
  isSmsEnabled: boolean
  deliveryDate: string
  body: string
  language: string
}

const initialState: DeliverCampaignSliceState = {
  emailSmsDetails: {
    emailSmsLanguage: "en",
    testEmailSmsDetails: null,
    emailData: {
      subject: "",
      isEmailEnabled: false,
      deliveryDate: "",
      bannerImage: "",
      body: "",
    },
    smsData: {
      isSmsEnabled: false,
      deliveryDate: "",
      body: "",
      language: "",
    },
  },
  discountDetails: null,
  receiverDetails: {
    totalCount: 0,
    pageCount: 1,
    spsPayDetails: null,
    isReadyToProcess: false,
  },
}

export const deliverCampaignDataSlice = createAppSlice({
  name: "deliverCampaignDataSlice",
  initialState,
  reducers: create => ({
    updateSpsPayDetails: create.reducer(
      (state, action: PayloadAction<spsPayDetails | null>) => {
        state.receiverDetails.spsPayDetails = action.payload
      },
    ),
    updateDeliverDetails: create.reducer(
      (
        state,
        action: PayloadAction<{
          emailData: EmailDetails
          smsData: SmsDetails
          emailSmsLanguage: string
          testEmailSmsDetails: testEmailSmsDetails
        }>,
      ) => {
        state.emailSmsDetails = action.payload
      },
    ),
    updateDiscountDetails: create.reducer(
      (state, action: PayloadAction<DiscountDetails>) => {
        state.discountDetails = action.payload
      },
    ),
    updateTotalCount: create.reducer((state, action: PayloadAction<number>) => {
      state.receiverDetails.totalCount = action.payload
    }),
    updatePageCount: create.reducer((state, action: PayloadAction<number>) => {
      state.receiverDetails.pageCount = action.payload
    }),
    updateIsReadyToProcess: create.reducer(
      (state, action: PayloadAction<boolean>) => {
        state.receiverDetails.isReadyToProcess = action.payload
      },
    ),
    resetDeliveryDetails: () => initialState,
  }),
  selectors: {
    selectDiscountDetails: appData => appData.discountDetails,
    selectSpsPayData: appData => appData.receiverDetails.spsPayDetails,
    selectEmailData: appData => appData.emailSmsDetails.emailData,
    selectSmsData: appData => appData.emailSmsDetails.smsData,
    selectEmailSmsLanguage: appData => appData.emailSmsDetails.emailSmsLanguage,
    selectTotalCount: appData => appData.receiverDetails.totalCount,
    selectPageCount: appData => appData.receiverDetails.pageCount,
    selectIsReadyToProcess: appData => appData.receiverDetails.isReadyToProcess,
    selectTestEmailSmsDetails: appData =>
      appData.emailSmsDetails.testEmailSmsDetails,
  },
})

export const {
  updateDeliverDetails,
  resetDeliveryDetails,
  updateTotalCount,
  updateSpsPayDetails,
  updateDiscountDetails,
  updatePageCount,
  updateIsReadyToProcess,
} = deliverCampaignDataSlice.actions
export const {
  selectSpsPayData,
  selectEmailData,
  selectSmsData,
  selectEmailSmsLanguage,
  selectTestEmailSmsDetails,
  selectDiscountDetails,
  selectTotalCount,
  selectPageCount,
  selectIsReadyToProcess,
} = deliverCampaignDataSlice.selectors
